import styled from "styled-components";

export const StyledSocialIcons = styled.div`
  position: fixed;
  left: 20px;
  bottom: 100px;
  opacity: 1;

  @media (max-width: 1400px) {
    display: none;
  }
`;

export const StyledSocialIcon = styled.a`
  opacity: 1;
  width: 2rem;

  display: flex;

  cursor: pointer;
  font-size: 14px;

  margin-bottom: 20px;

  svg {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.socialIcons};
    transition: ${({ theme }) => theme.transitionSocialIcons};
    &:hover {
      color: ${({ theme }) => theme.colors.socialIconsHover};
      transform: translateY(-0.2rem);
      transition: all 300ms ease-in-out;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 1.8rem;
    width: 1.8rem;
    margin-top: 2rem;
  }
`;

export const StyledTextEmailInverted = styled.a`
  opacity: 1;
  position: fixed;
  font-size: 1.4rem;
  height: 200px;
  width: 2px;

  left: 42px;
  bottom: 350px;
  writing-mode: vertical-lr;
  text-align: center;

  transform: rotate(180deg);

  font-size: 2rem;

  display: flex;

  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    transform: rotate(180deg) translateY(0.5rem);

    color: ${({ theme }) => theme.colors.textEmailInvertedHover};
  }
`;

export const StyledFaIconInverted = styled.div`
  opacity: 1;
  position: fixed;
  transform: rotate(90deg);
  bottom: 200px;
  display: flex;
  font-size: 2.1rem;

 
`;

export const StyledVerticalLine = styled.div`
  opacity: 1;

  position: fixed;
  height: 100px;
  width: 2px;
  bottom: -1px;
  left: 31px;

  background: linear-gradient(
    to top,
    ${({ theme }) => theme.colors.lineVerticalGradientOne} 0%,
    ${({ theme }) => theme.colors.lineVerticalGradientTwo} 100%
  );
`;

export const StyledMailInvertedContainer = styled.div``;
