import { StyledSectionHeading } from "../styles/common/SectionHeading.style";
import { StyledProjectDescription, StyledProjectImageContainer, StyledProjectsSection, StyledSingleProject, StyledTags } from "../styles/sections/ProjectsSection.styled";
import { Projects } from "../../data/Projects";
import { FaEye, FaGithub, FaYoutube } from "react-icons/fa";


import { useTranslation } from "react-i18next";

export const ProjectsSection = () => {
 
  const { t } = useTranslation(); 

  return (
    <>
      <StyledProjectsSection id="portfolio" >
        <StyledSectionHeading>
          <h1 data-aos="flip-right">{t('HeaderPortfolio')}</h1>
        </StyledSectionHeading>

        <div>
          {Projects && Projects.map((project, index) => (
            <StyledSingleProject key={index} data-aos="zoom-in" >
                <StyledProjectImageContainer href={project.projectLink} target="_blank">
                  <img src={process.env.PUBLIC_URL+project.image} alt={t(project.title)}/>
                </StyledProjectImageContainer>
                <StyledProjectDescription>
                  <h1>{t(project.title)}</h1>
                  <StyledTags>
                    {project.tags && project.tags.map((tag,tagIndex) => (
                      <span key={tagIndex}>{tag}</span>
                    ))}
                  </StyledTags>
                
                 <p dangerouslySetInnerHTML={{ __html: t(project.paragraph) }}></p>

                  <div>
                    {Boolean(project.codeLink) && (
                      <a href={project.codeLink} rel="noreferrer" target="_blank">
                        <FaGithub />
                        <span>{t('voirLeCode')}</span>
                      </a>
                    )}
                    {Boolean(project.projectLinkText) && (
                      <a href={project.projectLink} rel="noreferrer" target="_blank">
                        <FaEye />
                        <span>{t(`${project.projectLinkText}`)}</span>

                      </a>
                    )}
                 
                    {Boolean(project.youtubeLink) && (
                      <a href={project.youtubeLink} rel="noreferrer" target="_blank">
                        <FaYoutube />
                        <span>{t('voirLaVideoDePresentation')}</span>
                      </a>
                    )}
                  </div>
                </StyledProjectDescription>
            </StyledSingleProject>
          ))}
        </div>

      </StyledProjectsSection>
      <hr data-aos="flip-right" data-aos-once="true"/>
    </>
  );
};
