import { GlobalStyles } from "./components/styles/Globals.styled";
import { ThemeProvider } from "styled-components";
import { NavBar } from "./components/layout/NavBar";
import { HeroSection } from "./components/sections/HeroSection";
import { StyledContainer } from "./components/styles/common/Container.styled";
import { AboutSection } from "./components/sections/AboutSection";
import { ProjectsSection } from "./components/sections/ProjectsSection";
import { SkillsSection } from "./components/sections/SkillsSection";
import { ContactSection } from "./components/sections/ContactSection";
import { Footer } from "./components/layout/Footer";
import { useState } from "react";
import { SocialIcons } from "./components/layout/SocialIcons";
import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect } from "react";

import i18n from '../src/components/translation/i18n'; // le fichier de configuration que vous avez créé à l'étape précédente
import { I18nextProvider } from 'react-i18next';





const App = () => {
  
 
  useEffect(() => {
    AOS.init({
      offset: 50,
      duration: 600,
      easing: "ease-in-out",
      delay: 100,
    });
    
 
    setTimeout(() => {
      AOS.refresh();
  
    }, 3000);
  }, []);


  const [darkMode, setDarkMode] = useState(true);
  const theme = {
    colors: {
      white: darkMode ? "rgba(240, 247, 255,1)" : "rgba(0, 0, 0,1)",
      navyBlue: darkMode ? "rgba(8, 31, 56, 1)" : "rgba(245, 245, 245, 1)",
      lightNavyBlue: darkMode ? "rgba(4, 22, 48, 1)" :"rgba(222, 228, 234, 1)",
      green: darkMode ? "rgba(51, 197, 129, 1)" : "rgba(255, 255, 255, 1)",
      orange: darkMode ? "rgba(251, 46, 14, 1)" :"rgba(252, 199, 12, 0.81)",
      purple: darkMode ? "rgba(139, 5, 186, 1)" :"rgba(255, 255, 255, 1)",
      navBarColor: darkMode ? "rgba(4, 22, 48, 1)" : "rgba(222, 228, 234, 1)",
      heroSectionHeaderLinearOneSvg: darkMode ? "rgba(8, 31, 56, 0.9) 0%" : "rgba(8, 31, 56, 0.9) 0%",
      heroSectionHeaderLinearTwoSvg: darkMode ? "rgba(50, 25, 72, 1) 100%" : "rgba(51, 51, 51, 1)100%",
      htmlBackground: darkMode ? "rgba(10,25,51,1)" : "rgba(245, 245, 245, 1)",
      lineMobile: darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)",
      // title gradient
      titleLinearGradientOne: darkMode? "rgba(200, 80, 0, 1)" : "rgba(0, 75, 159, 1)",
      titleLinearGradientTwo: darkMode? "rgba(255, 141, 0, 1)" : "rgba(52, 123, 185, 1)",
      // hr
      lineHrOne: darkMode? "rgba(140, 25, 72, 1)" : "rgba(0,0,0,1)",
      lineHrTwo: darkMode ? "rgba(200, 50, 72, 1)" : "rgba(0,0,0,1)",
      // a links
      aLink: darkMode ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
      aLinkTwo: darkMode ? "rgba(0, 0, 0, 1)" : "rgba(255, 255, 255, 1)",
      aLinkHover: darkMode ? "rgba(255, 141, 0, 1)" :"rgba(0, 74, 140, 1)",
      // Skill
      singleSkillContainer: darkMode ? "rgba(15, 34, 76, 1)" :"rgba(222, 228, 234, 1)",
      singleSkillText: darkMode ? "rgba(255, 255, 255, 1)" :"rgba(0, 0, 0, 1)",
      // Webkit Colors
      webkitScrollbarTrack: darkMode ? "rgba(12, 43, 85, 1)" :"rgba(247, 247, 247, 1);",
      webkitScrollbarThumb : darkMode ? "rgba(101, 22, 54, 1)" :"rgba(52, 123, 185, 1)", ////////////////
      webkitScrollbarThumbHover : darkMode ? "rgba(140, 25, 72, 1)" :"rgba(252, 199, 12, 0.81)",
      // Button
      backgroundAndBorderButton : darkMode ? "rgba(140, 25, 72, 1)" : "rgba(52, 123, 185, 1)",
      textButtonOne :  darkMode ? "rgba(240, 247, 255, 1)" : "rgba(255, 255, 255, 1)",
      textButtonTwo :  darkMode ? "rgba(51, 197, 129, 1)" : "rgba(51, 51, 51, 1)",
      textButtonThree:  darkMode ? "rgba(240, 247, 255, 1)" : "rgba(0, 0, 0, 1)",
      aHoverBackgroundAndBorderButton : darkMode ? "rgba(255, 141, 0, 1)" :"rgba(0, 74, 140, 1)",
      // Arrow Down
      arrowDown: darkMode ? "rgba(140, 25, 72, 1)" : "rgba(255, 255, 255, 1)",
      arrowDownHover : darkMode ? "rgba(255, 141, 0, 1)" : "rgba(52, 123, 185, 1)",
      // Social icons:
      socialIcons: darkMode ? "rgba(240, 247, 255,1)" : "rgba(0, 0, 0, 1)",
      socialIconsHover: darkMode ? "rgba(255, 141, 0, 1)" : "rgba(38, 86, 136, 1)",
      // Line decoration
      lineDecoration : darkMode ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 1)",
      // Text email inverted
      textEmailInverted : darkMode ? "rgba(140, 25, 72, 1)" : "rgba(255, 255, 255, 1)",
      textEmailInvertedHover : darkMode ? "rgba(255, 141, 0, 1)" : "rgba(38, 86, 136, 1)",
      // Vertical Line
      lineVerticalGradientOne : darkMode ? "rgba(240, 247, 255,1)" : "rgba(0,0,0,1)",
      lineVerticalGradientTwo : darkMode? "rgba(240, 247, 255,1)" : "rgba(38, 86, 136, 1)",
      // Portfolio
      portfolioTag : darkMode ? "rgba(142, 33, 60, 1)" : "RGBA(0, 77, 153, 0.2)",
      // Title
      titleGregoryDeMeur : darkMode ? "rgba(240, 247, 255,1)" : "rgba(255, 247, 255,1)",

      transitionSocialIcons : "all 650ms ease-in-out",

      languageToggleBackground: darkMode ? "rgba(222, 228, 234, 1)":"rgba(52, 123, 185, 1)",
      languageToggleActive: darkMode ? "rgba(52, 123, 185, 1)" : "rgba(222, 228, 234, 1)",
      languageToggleText: darkMode ? "rgba(222, 228, 234, 1)" : "rgba(0, 20, 20, 1)",
      languageToggleTextTwo: darkMode ? "rgba(0, 20, 20, 0.65)" : "rgba(220, 220, 220, 0.65)",
    },
    mobile: "860px",
    transition: "all 650ms ease-in-out",
  };
  return (
    <>
    <I18nextProvider  i18n={i18n}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <NavBar theme={theme} setDarkMode={setDarkMode} darkMode={darkMode} />
        
        <HeroSection />
       
        <StyledContainer>
          <SocialIcons />
          <AboutSection />
          <SkillsSection />
          <ProjectsSection />
          <ContactSection />

          <Footer />
        </StyledContainer>
      </ThemeProvider>
      </I18nextProvider>
    </>
  );
};

export default App;
