import styled from "styled-components";

export const StyledContainer = styled.div`
    width: 1230px;
    max-width: 100%;
    padding: 0 5rem;
    margin: 0 auto;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
        padding: 0 2rem;
    }
   
`