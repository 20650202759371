import { useState, useEffect, useRef } from "react";
import { NavItems } from "../../data/NavItems";
import {
  LineOne,
  LineThree,
  LineTwo,
  Logo,
  MenuIcon,
  Nav,
  NavList,
  NavItemStyled,
  NavLink,
  Profile,
  SocialIcons,
  StyledSunMoonMobile,

} from "../styles/layout/Nav.styled";

import { FaGithub, FaLinkedin } from "react-icons/fa";

import useScrollDirection from "../../hooks/useScrollDirection";

import LanguageToggle from "../styles/common/LanguageToggle";

import { useTranslation } from 'react-i18next';



export const NavBar = ({ setDarkMode, darkMode, theme }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const scrollDirection = useScrollDirection("down");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

   
    handleResize();

 
    window.addEventListener("resize", handleResize);

 
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const [language, setLanguage] = useState('fr');

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);

  };

 
  const menuRef = useRef(null);

 
  useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, []);





  return (
    <>
    <div ref={menuRef}>
      <Nav scrollDirection={scrollDirection} menuOpen={menuOpen}>
     
        <div>
          <a href="#home">
            <Logo
              id="logo-gdm"
              src={
                darkMode
                  ? process.env.PUBLIC_URL +
                    "/assets/images/logo/logo-gdm-dark.png"
                  : process.env.PUBLIC_URL + "/assets/images/logo/logo-gdm.png"
              }
            />
          </a>
        </div>

      

        
        <MenuIcon menuOpen={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
          <LineOne menuOpen={menuOpen} />
          <LineTwo menuOpen={menuOpen} />
          <LineThree menuOpen={menuOpen} />
        </MenuIcon>

     

       
        {/* Sun & moon icon */}
        {windowWidth <= 861 && (
          <StyledSunMoonMobile
            onClick={() => setDarkMode(!darkMode)}
            style={{ cursor: "pointer" }}
          >
            {darkMode ? (
              <svg
                className="icon-moon"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M2.03 12.42c.36 5.15 4.73 9.34 9.96 9.57 3.69.16 6.99-1.56 8.97-4.27.82-1.11.38-1.85-.99-1.6-.67.12-1.36.17-2.08.14C13 16.06 9 11.97 8.98 7.14c-.01-1.3.26-2.53.75-3.65.54-1.24-.11-1.83-1.36-1.3C4.41 3.86 1.7 7.85 2.03 12.42z"
                ></path>
              </svg>
            ) : (
              <svg
                className="icon-sun"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g fill="#000" clipPath="url(#clip0_1300_106215)">
                  <path d="M12 0a1 1 0 011 1v3a1 1 0 11-2 0V1a1 1 0 011-1z"></path>
                  <path
                    fillRule="evenodd"
                    d="M7 12a5 5 0 1110 0 5 5 0 01-10 0zm5-3a3 3 0 100 6 3 3 0 000-6z"
                    clipRule="evenodd"
                  ></path>
                  <path d="M20.485 4.929a1 1 0 00-1.414-1.414L16.95 5.636a1 1 0 001.414 1.414l2.121-2.121zM24 12a1 1 0 01-1 1h-3a1 1 0 110-2h3a1 1 0 011 1zM19.071 20.485a1 1 0 001.414-1.414l-2.121-2.121a1 1 0 10-1.414 1.414l2.121 2.121zM12 24a1 1 0 01-1-1v-3a1 1 0 112 0v3a1 1 0 01-1 1zM3.515 19.071a1 1 0 101.414 1.414l2.121-2.121a1 1 0 00-1.414-1.414L3.515 19.07zM0 12a1 1 0 011-1h3a1 1 0 110 2H1a1 1 0 01-1-1zM4.929 3.515a1 1 0 10-1.414 1.414L5.636 7.05A1 1 0 007.05 5.636L4.93 3.515z"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1300_106215">
                    <path fill="#fff" d="M0 0H24V24H0z"></path>
                  </clipPath>
                </defs>
              </svg>
            )}
          </StyledSunMoonMobile>
        )}


        <NavList menuOpen={menuOpen} >
          {NavItems &&
            NavItems.map((navItem, index) => (
              <NavItemStyled
                key={index}
                index={index}
                menuOpen={menuOpen}
                onClick={() => setMenuOpen(false)}
              >
                {navItem.isProfile ? (
                  <NavLink href="#home">
                    <Profile>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/profile_picture/profile_picture.png"
                        }
                        alt="GDM"
                      ></img>
                    </Profile>
                  </NavLink>
                ) : null}

                {navItem.textKey ? (
                 <NavLink href={navItem.href}>{t(navItem.textKey)}</NavLink>

                ) : null}
                {navItem.hasSocialIcons && windowWidth <= 861 ? (
  <SocialIcons>
    <NavLink href="https://github.com/GregoryDeMeur/" target="_blank">
      <FaGithub />
    </NavLink>
    <NavLink href="https://www.linkedin.com/in/gregory-de-meur/" target="_blank">
      <FaLinkedin />
    </NavLink>
  </SocialIcons>
) : null}
              </NavItemStyled>
            ))}
    {/* Language */}
    
    <LanguageToggle currentLanguage={language} changeLanguage={changeLanguage} menuOpen={menuOpen}/>



    
          {/* Sun & moon icon */}
          {windowWidth >= 861 && (
            <div
              onClick={() => setDarkMode(!darkMode)}
              style={{ cursor: "pointer" }}
            >
              {darkMode ? (
                <svg
                  className="icon-moon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M2.03 12.42c.36 5.15 4.73 9.34 9.96 9.57 3.69.16 6.99-1.56 8.97-4.27.82-1.11.38-1.85-.99-1.6-.67.12-1.36.17-2.08.14C13 16.06 9 11.97 8.98 7.14c-.01-1.3.26-2.53.75-3.65.54-1.24-.11-1.83-1.36-1.3C4.41 3.86 1.7 7.85 2.03 12.42z"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="icon-sun"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <g fill="#000" clipPath="url(#clip0_1300_106215)">
                    <path d="M12 0a1 1 0 011 1v3a1 1 0 11-2 0V1a1 1 0 011-1z"></path>
                    <path
                      fillRule="evenodd"
                      d="M7 12a5 5 0 1110 0 5 5 0 01-10 0zm5-3a3 3 0 100 6 3 3 0 000-6z"
                      clipRule="evenodd"
                    ></path>
                    <path d="M20.485 4.929a1 1 0 00-1.414-1.414L16.95 5.636a1 1 0 001.414 1.414l2.121-2.121zM24 12a1 1 0 01-1 1h-3a1 1 0 110-2h3a1 1 0 011 1zM19.071 20.485a1 1 0 001.414-1.414l-2.121-2.121a1 1 0 10-1.414 1.414l2.121 2.121zM12 24a1 1 0 01-1-1v-3a1 1 0 112 0v3a1 1 0 01-1 1zM3.515 19.071a1 1 0 101.414 1.414l2.121-2.121a1 1 0 00-1.414-1.414L3.515 19.07zM0 12a1 1 0 011-1h3a1 1 0 110 2H1a1 1 0 01-1-1zM4.929 3.515a1 1 0 10-1.414 1.414L5.636 7.05A1 1 0 007.05 5.636L4.93 3.515z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1300_106215">
                      <path fill="#fff" d="M0 0H24V24H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
              )}
            </div>
          )}

        


        </NavList>
        
 
   
      </Nav>
       </div>
    </>
  );
};
