import styled from "styled-components";

export const StyledSectionHeading = styled.section`
    text-align: center;
    margin-bottom: 7rem;
    align-items:center;

    h1 {
        text-transform: uppercase;
        display: inline-block;
        font-size: 4rem;
        margin: 0.5rem 0 1.5rem 0;
        position: relative;

       
    }
    

    p {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        margin-bottom: 5rem;
        margin-top:3rem;
    }

    @media (max-width: ${({theme}) => theme.mobile}){
        
 
        margin-bottom: 3rem;
        margin-top:0rem;
        h1 {
            
        }
        p{
            
        }
      
    }

`