


export const NavItems = [

  {
    isProfile: true,
  },
  {
    href: "#about",
    textKey: "navabout",
  },
  {
    href: "#skills",
    textKey: "navskills",
  },
  {
    href: "#portfolio",
    textKey: "navportfolio",
  },
  {
    href: "#contact",
    textKey: "navcontact",
  },
  /*
  {
    hasSocialIcons: true,
  },
  {
    hasCTA: true,
  },*/
];
