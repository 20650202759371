import styled from "styled-components";

export const CTAButton = styled.a`
    background: ${ ({primary, theme}) => primary ? theme.colors.backgroundAndBorderButton : "none" };
    color: ${ ({primary, theme}) => primary ? theme.colors.textButtonOne : theme.colors.textButtonOne };
    padding: 10px 20px;
    margin: ${ ({margin}) => margin};
    border: 3px solid ${ ({theme}) => theme.colors.backgroundAndBorderButton};
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    border-radius: 20px;

    &:hover{
        background: ${ ({primary, theme}) => primary ? theme.colors.aHoverBackgroundAndBorderButton : "none" };
    color: ${ ({primary, theme}) => primary ? theme.colors.textButtonOne : theme.colors.textButtonOne };
    border: 3px solid ${ ({theme}) => theme.colors.aHoverBackgroundAndBorderButton};
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        padding: 8px 16px;
    }

`