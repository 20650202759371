

import { StyledSectionHeading } from "../styles/common/SectionHeading.style";
import {
  StyledAboutContent,
  StyledAboutSection,
  StyledImage,
  StyledImageContainer,
} from "../styles/sections/AboutSection.styled";

import { useTranslation, Trans  } from 'react-i18next';


export const AboutSection = () => {

  const { t } = useTranslation();

  return (
    <>
      <StyledAboutSection id="about"  >
  
        <StyledImageContainer data-aos="fade-right">
          <StyledImage
            src={process.env.PUBLIC_URL + "/assets/images/profile_picture/profile_picture.png"}
            alt="Gregory De Meur"
            
          />
        </StyledImageContainer>
        <StyledAboutContent>
          <StyledSectionHeading>
            <h1 data-aos="fade-right">{t('HeaderAbout')}</h1>
          </StyledSectionHeading>
          <div data-aos="fade-left">
          <p>
            <Trans i18nKey="pAbout1" />
          </p>
          <p>
          <Trans i18nKey="pAbout2" />
          </p>
          <p>
          <Trans i18nKey="pAbout3" />
          </p>
          
          <p>
          <Trans i18nKey="pAbout4" />
          </p>
          </div>
        </StyledAboutContent>
      </StyledAboutSection>
      <hr data-aos="flip-right"/>
    </>
  );
};
