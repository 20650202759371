import { StyledFooter } from "../styles/layout/Footer.styled";

export const Footer = () => {
  return(
 
    <StyledFooter>
      <p> <a href="https://www.gregorydemeur.be">&copy; {new Date().getFullYear()} - Portfolio - Gregory De Meur</a></p>
    </StyledFooter>
  
  )
  
};
