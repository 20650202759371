import { CTAButton } from "../styles/common/CTAButton.styled";
import {
  StyledArrowDown,
  StyledHeroContent,
  StyledHeroSection,

} from "../styles/sections/HeroSection.styled";
import { ImArrowDown } from "react-icons/im";

import { useTranslation  } from 'react-i18next';

export const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <StyledHeroSection id="home">
      
      
      <StyledHeroContent>
        <h1>Gregory De Meur</h1>
        <h3>{t('heroTitle')}</h3>

        <div>
          <CTAButton href="#about">{t('heroAboutMe')}</CTAButton>
          <CTAButton href="#portfolio" primary margin="0 0 0 20px">
          {t('heroPortfolio')}
          </CTAButton>
          
        </div>
      </StyledHeroContent>
      <StyledArrowDown href="#about">
        <ImArrowDown />
      </StyledArrowDown>

      
    </StyledHeroSection>
  );
};
