import React from 'react';
import styled, { keyframes } from 'styled-components';

const slideAnimation = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;


const ToggleContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.languageToggleBackground};
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 15px;
  margin-right: 30px;

  
  
  @media (max-width: ${({ theme }) => theme.mobile}) {

    margin-left:0px;
    margin-right:0px;
    margin-top : 20px;
    text-align: center;

    
  }
`;

const Language = styled.span`
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${props => props.active ? props.theme.colors.languageToggleActive : 'transparent'};
  color: ${props => props.active ? props.theme.colors.languageToggleText : props.theme.colors.languageToggleTextTwo};
  font-weight: bold;
`;



const LanguageToggle = ({ currentLanguage, changeLanguage }) => {
  return (
    <ToggleContainer onClick={() => changeLanguage(currentLanguage === 'fr' ? 'en' : 'fr')}>
      <Language active={currentLanguage === 'fr'}>FR</Language>
      <Language active={currentLanguage === 'en'}>EN</Language>
    </ToggleContainer>
  );
}



export default LanguageToggle;
