import styled, { keyframes } from "styled-components";

const float = keyframes`
from{
    transform: translate(0px,0px);
}
65%{
    transform: translate(0px,10px);
}
to{
    transform: translate(0px,0px);
}
`;

export const StyledContactSection = styled.section`
  min-height: 90vh;
  padding: 5rem 0 5rem 0;
  width: 100%;
 
  @media (max-width: ${({theme}) => theme.mobile}){
    h1{
      font-size:3rem;
    }
  }
`;

export const StyledDetailsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column-reverse;
    margin-bottom: 4rem;
   margin: 0 auto;
   display: block;
  }
`;

export const StyledContactInfo = styled.div`
  flex: 1;
  font-family: "Poppins", sans-serif;
  font-size: 18px;

  p {
    padding: 0;
    margin-bottom: 2rem;
    text-align: justify;
  }
  div {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      margin: 0 2rem 2rem 0;

      svg {
        margin-right: 0.5rem;
      }
    }
    @media (max-width: ${({ theme }) => theme.mobile}) {
      align-items: flex-start;
      flex-direction: column;

      a {
        margin: 0 0 2rem 0;
        font-size: 16px;
      }
    }
  }
`;

export const StyledImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
   user-select: none;

  img {
    width: 80%;
    animation: ${float} 3s ease-in-out infinite;
    user-select: none; 
    pointer-events: none;
  
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  @media (max-width: 860px) {
display:none;
justify-content: center;
  }
`;

export const StyledContactForm = styled.div`

`