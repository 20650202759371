import { AiOutlineMail } from "react-icons/ai";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import {
  StyledFaIconInverted,
  StyledMailInvertedContainer,
  StyledSocialIcon,
  StyledSocialIcons,
  StyledTextEmailInverted,
  StyledVerticalLine,
} from "../styles/common/SocialIcons.styled";

export const SocialIcons = () => {
  return (
    <>
      <StyledSocialIcons>
        <StyledSocialIcon href="https://www.linkedin.com/in/gregory-de-meur/" target="_blank">
          <FaLinkedin />
        </StyledSocialIcon>
        <StyledSocialIcon href="https://github.com/GregoryDeMeur" target="_blank">
          <FaGithub />
        </StyledSocialIcon>

        <StyledVerticalLine />
        <StyledMailInvertedContainer>
          <StyledTextEmailInverted href="mailto:contact@gregorydemeur.be">
            <StyledFaIconInverted>
              <AiOutlineMail />
            </StyledFaIconInverted>
            &nbsp;contact@gregorydemeur.be
          </StyledTextEmailInverted>

          <StyledFaIconInverted></StyledFaIconInverted>
        </StyledMailInvertedContainer>
      </StyledSocialIcons>
    </>
  );
};
