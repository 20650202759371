import {
  StyledContactSection,
  StyledDetailsWrapper,
  StyledImageWrapper,
} from "../styles/sections/ContactSection.styled";
import { StyledSectionHeading } from "../styles/common/SectionHeading.style";
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";
import styled from "styled-components";
import { useRef } from "react";
import { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { useLottie } from "lottie-react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useTranslation } from "react-i18next";

export const ContactFormStyled = styled.div`
  width: 50%;
  //margin-bottom:8rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
      &:hover {
        color: ${({ theme }) => theme.colors.aLinkHover};
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      margin-bottom: 3rem;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: 0.3rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: ${({ theme }) => theme.colors.backgroundAndBorderButton};
      width: 100%;

      color: ${({ theme }) => theme.colors.textButtonOne};

      font-size: 18px;
      font-family: "Montserrat", sans-serif;

      border-radius: 10px;

      &:hover {
        background: ${({ theme }) =>
          theme.colors.aHoverBackgroundAndBorderButton};
        color: ${({ theme }) => theme.colors.textButtonOne};
      }
    }
  }
`;

export const LoadingTextStyled = styled.div`
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 9999;
  p {
    font-size: 18px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 13px;
    }
  }
`;

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.modalSendForm};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
`;

export const LoadingIndicatorStyled = styled.div`
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 9999;

  width: 60%;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textButtonThree};
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 5px;
  margin-top: 10px;

  &:hover {
    color: ${({ theme }) => theme.colors.aLinkHover};
  }
`;

export const StyledIcon = styled.i`
  font-size: 24px;
  margin-right: 10px;
`;

export const StyledContactLinks = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
`;

const AnimatedImageJson = () => {
  const imageNameAnimation = require("../../lottieJson/loading.json");

  const options = {
    animationData: imageNameAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  return <>{View}</>;
};

export const ContactSection = () => {
  const { t } = useTranslation(); 
  
  const form = useRef();
  const [formSent, setFormSent] = useState(false);
  const [, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSending(true);

    // Récupérer les valeurs des champs du formulaire
    const userName = form.current.user_name.value;
    const userEmail = form.current.user_email.value;
    const userMotif = form.current.user_motif.value;
    const messageContent = form.current.message.value;

    // Ajoute le champ "Reply-To" à l'en-tête de l'e-mail
    const headers = {
      "Reply-To": form.current.user_email.value,
    };

    if (!userName || !userEmail || !userMotif || !messageContent) {
      toast.error(t('errorEmptyFields'), {
        toastId: "required-fields",
      });
      setSending(false);
      return;
    }

    if (!validateEmail(userEmail)) {
      toast.error(
        t('errorIncorrectMail'),
        {
          toastId: "invalid-email",
        }
      );
      setSending(false);
      return;
    }

    if (messageContent.length < 10) {
      toast.error(t('errorMessageShort'), {
        toastId: "message-too-short",
      });
      setSending(false);
      return;
    }

    emailjs
      .sendForm(
        "service_fal2s4w",
        "template_0jp0t4j",
        form.current,
        "PvmeA0ZQ2sc3iAlPw",
        headers // Passe le header en paramètre à la méthode sendForm()
      )
      .then(
        (result) => {
      
        
          setFormSent(true);
   
          toast.success(t('contactMessageSent'));;
          form.current.reset();
        },
        (error) => {
          setFormSent(false);
          setMessage(t('contactMessageSentError'));
          toast.error(t('contactMessageSentError'));
        }
      )
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    if (formSent) {
      const timer = setTimeout(() => {
        setFormSent(false);
        setMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [formSent]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

 
 
  return (
    <>
      <StyledContactSection id="contact">
        <StyledSectionHeading>
          <h1 data-aos="flip-left" data-aos-once="true">
            Contact
          </h1>
        </StyledSectionHeading>
        <StyledDetailsWrapper>
          <ContactFormStyled data-aos-once="true" data-aos="fade-right">
            <form ref={form} onSubmit={sendEmail}>
              <label>{t('contactFormName')}</label>
              <input type="text" name="user_name" required />
              <label>Email</label>
              <input type="email" name="user_email" required />
              <label>{t('contactFormMotif')}</label>
              <input type="text" name="user_motif" required />
              <label>Message</label>
              <textarea name="message" required />

              {!sending && (
                <input type="submit" disabled={sending} value={t('contactFormSend')} />
              )}
            </form>

            <StyledContactLinks data-aos="zoom-in" data-aos-once="true">
              <StyledLink href="mailto:contact@gregorydemeur.be">
                <StyledIcon>
                  <FaEnvelope />
                </StyledIcon>
                <span>contact@gregorydemeur.be</span>
              </StyledLink>

              <StyledLink href="https://www.linkedin.com/in/gregory-de-meur/">
                <StyledIcon>
                  <FaLinkedin />
                </StyledIcon>
                <span>Gregory De Meur</span>
              </StyledLink>

              <StyledLink href="https://github.com/GregoryDeMeur">
                <StyledIcon>
                  <FaGithub />
                </StyledIcon>
                <span>Gregory De Meur</span>
              </StyledLink>
            </StyledContactLinks>
          </ContactFormStyled>

          {sending && (
            <ModalStyled>
              <LoadingIndicatorStyled>
                <AnimatedImageJson />

                <LoadingTextStyled>
                  <p>{t('envoiEnCours')}</p>
                </LoadingTextStyled>
              </LoadingIndicatorStyled>
            </ModalStyled>
          )}
          <ToastContainer />

          <StyledImageWrapper data-aos="zoom-in" data-aos-once="true">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/sections/contact/contact_purple.svg"
              }
              alt="contact"
            />
          </StyledImageWrapper>
        </StyledDetailsWrapper>
      </StyledContactSection>
      <hr />
    </>
  );
};
