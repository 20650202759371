import styled from "styled-components";

export const StyledSkillsSection = styled.section`
  min-height: 90vh;
  padding: 5rem 0 5rem 0;
  width: 100%;
  justify-content: center;
  text-align:center;
  
  h1{
    text-align:center;
    margin-top: 10px;
    font-size:4rem;
  }

  h2{
    text-align: center;
    margin-top: 30px;
    font-size:2rem;
  }

  @media (max-width: ${({theme}) => theme.mobile}){
    h1{
      font-size:3rem;
    }
  }
`;
export const StyledSkillsItems = styled.div`
  display: flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content: center;
  text-align:center;
  margin:1rem;
  
  

  @media screen {
    max-width: ${({ theme }) => theme.mobile} {
      flex-direction: column;
    }
  }
`;

export const StyledSingleSkill = styled.div`
min-height: 5rem;
min-width: 15rem;
max-width:15rem;
max-height:18rem;
border-radius: 7px;
background: ${({theme}) => theme.colors.singleSkillContainer};
color: ${({theme}) => theme.colors.singleSkillText};
padding:40px;

display:flex;
flex-direction: column;
justify-content: center;
align-items:center;
margin:1rem;





transition: all 300ms ease-in-out;
&:hover{
    transform: translateY(-1rem);
}






svg{
    
    width:100px;
    height:100px;
}




`

export const StyledIconWrapper = styled.div`
display:flex;
flex-shrink:0;
font-size: 28px;
align-items:center;
justify-content: center;
height: 4.5rem;
width: 4.5rem;
color: ${( {theme}) => theme.colors.navyBlue};
background: ${( {theme}) => theme.colors.white};
border-radius: 50%;
`