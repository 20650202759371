import styled from "styled-components";

export const StyledProjectsSection = styled.section`
  min-height: 90vh;
  padding: 5rem 0 5rem 0;
  width: 100%;

  @media (max-width: ${({theme}) => theme.mobile}){
    h1{
      font-size:3rem;
    }
    
  }

`;

export const StyledSingleProject = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(even) {
    flex-direction: row-reverse;
    margin: 10rem 0;

    a {
      img {
        max-height:350px;
        
      }
      div {
        
      }

      &:hover {
        img {
          transition: transform 0.3s ease-in-out;
          transform: scale(1.1);
        }

        div {
         
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;

    &:nth-child(even) {
      flex-direction: column;
      

      a {
        margin-bottom: 3rem;
        
        img {
       
        }
        div {
        
        }

        &:hover {
          div {
            transition: transform 0.3s ease-in-out;
          transform: scale(1.1);
          }
        }
      }
    }
  }
`;

export const StyledProjectImageContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  perspective: 1000px;

  img {
    width: 90%;
    border-radius: 50px;

    transition: ${({ theme }) => theme.transition};
    z-index: 3;
    margin: 0 5rem 0 5rem;
    min-width: 310px;
  }
  &:hover {
    img {
      transition: transform 0.3s ease-in-out;
          transform: scale(1.1);
    }

    div {
     
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 3rem;
    justify-content: flex-start;

    img {
      width: 80%;
      border-radius: 9px;
    }
  }
`;

export const StyledProjectDescription = styled.div`
  flex: 1;

  h1 {
    font-size: 2rem;
    margin: 0 0 2rem 0;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.titleLinearGradientOne} 0%,
      ${({ theme }) => theme.colors.titleLinearGradientTwo} 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    text-align: justify;
  }

  div {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      margin: 2rem 2rem 2rem 0;
      font-size: 1.2rem;

      svg {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const StyledTags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  span {
    margin-bottom: 0.5rem;
    background: ${({ theme }) => theme.colors.portfolioTag};
    display: inline-block;
    padding: 7px;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 1rem;
  }
`;
